import Certificates from 'pages/Certificates/Certificates'
import Licenses from 'pages/Licenses/Licenses'
import Login from 'pages/Login/Login'
import Users from 'pages/Users/Users'
import { FC } from 'react'

interface IRoute {
  id: string
  path: string
  component: FC
  layout?: boolean
}

interface IRoutes {
  [key: string]: IRoute
}

export const PUBLIC_ROUTES: IRoutes = {
  LOGIN: {
    id: 'login',
    path: '/login',
    component: Login,
    layout: false,
  },
}

export const ADMIN_ROUTES: IRoutes = {
  USERS: {
    id: 'users',
    path: '/users',
    component: Users,
  },
}

export const PRIVATE_ROUTES: IRoutes = {
  LICENSES: {
    id: 'licenses',
    path: '/licenses',
    component: Licenses,
  },
  CERTIFICATES: {
    id: 'certificates',
    path: '/certificates',
    component: Certificates,
  },
}
export const publicRoutes = Object.values(PUBLIC_ROUTES)
export const privateRoutes = Object.values(PRIVATE_ROUTES)
export const adminRoutes = Object.values(ADMIN_ROUTES)
export const allRoutes = { ...publicRoutes, ...privateRoutes, ...adminRoutes }
